import { motion, useTransform, useViewportScroll } from "framer-motion"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Image from "../components/image"
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"

const PagePractice = props => {
  // console.log(props.data.wpPage.seo)

  let {
    location,
    data: {
      wpPage: { title, content, featuredImage, seo, acf_practices },
    },
  } = props

  // console.log(acf_practices)

  const { scrollYProgress } = useViewportScroll()

  const up = useTransform(scrollYProgress, [0, 0.1, 0.3], ["0%", "75%", "150%"])

  const down = useTransform(
    scrollYProgress,
    [0, 0.2, 0.5],
    ["0%", "-20%", "-30%"]
  )

  return (
    <Layout location={location} title={title}>
      <SEO bodyClasses="practice" seo={seo} />
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-12 headerimage">
            <motion.div style={{ top: 0, y: down }}>
              <Image data={featuredImage} />
            </motion.div>
            <div className="container caption left">
              <div className="row">
                <div className="col-12">
                  <motion.div style={{ top: 0, y: up }}>
                    <h1>{content && parse(content)}</h1>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row article">
          <div className="col-md-3 heading">
            <h2>{acf_practices.headingWhoWeAre}</h2>
          </div>
          <div className="offset-md-1 col-md-6 copy">
            {acf_practices.copyWhoWeAre}
          </div>
        </div>
        <div className="row article">
          <div className="col-md-3 heading">
            <h2>{acf_practices.headingOurVision}</h2>
          </div>
          <div className="offset-md-1 col-md-6 copy">
            {acf_practices.copyOurVision}
          </div>
        </div>
        <div className="row article">
          <div className="col-md-3 heading">
            <h2>{acf_practices.headingOurPurpose}</h2>
          </div>
          <div className="offset-md-1 col-md-6 copy">
            {acf_practices.copyOurPurpose}
          </div>
        </div>
        <div className="row article">
          <div className="col-md-3 heading">
            <h2>{acf_practices.headingRecognition}</h2>
          </div>
          <div className="offset-md-1 col-md-6 copy">
            {acf_practices.copyRecognition}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PagePractice

export const pagePractice = graphql`
  {
    wpPage(databaseId: { eq: 20 }) {
      id
      title
      content
      featuredImage {
        node {
          ...fragmentImage
        }
      }
      acf_practices {
        headingWhoWeAre
        copyWhoWeAre
        headingOurVision
        copyOurVision
        headingOurPurpose
        copyOurPurpose
        headingRecognition
        copyRecognition
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
